import { Modal } from "@mui/material";
import React, { useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import Fade from "@mui/material/Fade";
import { selectWidth } from "../../utility/Redux/profile";
import { useFocus } from "../../utility/hooks/hooks";
import GeniusAlphatecKeyboard from "../../utility/component/geniusKeyboard";

const MasterHelp = ({
    status,
    style,
    title,
    onClose,
    rowId,
    list,
    columns,
    onRowClick,
    searchQuery,
    setSearchQuery,
    extraFilter,
    loading,
    enterSearch,
    setSearchType,
    tips,
    searchRef,
}) => {
    const headerRef = useRef(null);
    const windowWidth = useSelector(selectWidth);
    const [searchR, setSearchRFocus] = useFocus();
    return (
        <Modal
            open={status}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Fade
                in={status}
                style={{
                    transitionDelay: status ? "100ms" : "0ms",
                }}
            >
                <div style={style}>
                    <div className="w-full flex flex-col bg-white h-full ">
                        <div ref={headerRef}>
                            <div
                                style={{
                                    border: "1px solid black",
                                    borderTopRightRadius: "8px",
                                    borderTopLeftRadius: "8px",
                                    borderBottom: "0px",
                                    overflow: "hidden",
                                }}
                                className="flex flex-col bg-white Fade"
                            >
                                <div
                                    style={{
                                        fontSize: "14px",
                                    }}
                                    className="font-semibold text-[#4a4a4a] bg-[#f5f0e8] font-arial p-3"
                                >
                                    {title}
                                </div>
                            </div>
                        </div>
                        <div
                            style={{
                                height: headerRef.current
                                    ? `calc(100% - ${headerRef.current.clientHeight}px)`
                                    : "80%",
                            }}
                            className="t:flex "
                        >
                            <div className="h-full ">
                                <div
                                    style={{
                                        height: `calc(100% - ${38}px)`,
                                    }}
                                >
                                    <DataGrid
                                        onRowClick={onRowClick}
                                        hideFooter={list.length < 100}
                                        headerHeight={0}
                                        rowHeight={30}
                                        showCellRightBorder={true}
                                        showColumnRightBorder={true}
                                        disableColumnMenu={true}
                                        getRowId={rowId}
                                        loading={loading}
                                        components={{
                                            Header: () => {
                                                const headerDetails = columns;
                                                return (
                                                    <div className="  bg-[#f5f0e8] flex ">
                                                        {headerDetails?.map(
                                                            (item, index) => {
                                                                return (
                                                                    <div
                                                                        key={
                                                                            index
                                                                        }
                                                                        className="  text-#4a4a4a flex justify-center items-center py-2  "
                                                                        style={
                                                                            item.flex
                                                                                ? index <
                                                                                  headerDetails.length -
                                                                                      1
                                                                                    ? {
                                                                                          flex: item.flex,
                                                                                          borderRight:
                                                                                              "1px solid black",
                                                                                          borderBottom:
                                                                                              "1px solid black",
                                                                                      }
                                                                                    : {
                                                                                          flex: item.flex,
                                                                                          borderBottom:
                                                                                              "1px solid black",
                                                                                      }
                                                                                : index <
                                                                                  headerDetails.length -
                                                                                      1
                                                                                ? {
                                                                                      width: item.width,

                                                                                      borderRight:
                                                                                          "1px solid black",
                                                                                      borderBottom:
                                                                                          "1px solid black",
                                                                                  }
                                                                                : {
                                                                                      width: item.width,
                                                                                      borderBottom:
                                                                                          "1px solid black",
                                                                                  }
                                                                        }
                                                                    >
                                                                        {item.renderHeader()}
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                    </div>
                                                );
                                            },
                                        }}
                                        sx={
                                            window.innerWidth < 770
                                                ? {
                                                      backgroundColor: "white",

                                                      fontSize: 13,
                                                      "& .super-app-theme--header":
                                                          {
                                                              backgroundColor:
                                                                  "#f5f0e8",
                                                              display: "none",
                                                          },
                                                      borderTop:
                                                          "1px solid black",
                                                      borderRadius: 0,
                                                  }
                                                : {
                                                      backgroundColor: "white",
                                                      fontSize: 15,
                                                      border: "1px solid black",
                                                      borderBottom:
                                                          "0px solid black",
                                                      borderRadius: 0,
                                                      "& .super-app-theme--header":
                                                          {
                                                              backgroundColor:
                                                                  "#f5f0e8",
                                                              borderRight: `1px solid black`,

                                                              display: "none",
                                                          },
                                                  }
                                        }
                                        columns={columns}
                                        rows={list}
                                        pageSize={100}
                                        rowsPerPageOptions={[100]}
                                        experimentalFeatures={{
                                            newEditingApi: true,
                                        }}
                                        disableColumnSelector
                                        disableColumnReorder
                                    />
                                </div>

                                <div
                                    style={{
                                        borderLeft: "1px solid black",
                                        borderRight: "1px solid black",
                                        borderBottom: "0px solid black",

                                        fontSize: "14px",
                                        backgroundColor: "#f5f0e8",
                                    }}
                                    className="flex  "
                                >
                                    <div className="flex-1 flex">
                                        <i className="bi bi-search pr-2 p-2 text-orange"></i>
                                        <input
                                            type={"text"}
                                            autoFocus={true}
                                            ref={
                                                searchRef ? searchRef : searchR
                                            }
                                            placeholder="Search"
                                            value={searchQuery}
                                            onChange={(t) => {
                                                setSearchQuery(t.target.value);
                                            }}
                                            onKeyDown={(e) => {
                                                if (
                                                    e.code === "Enter" ||
                                                    e.code === "NumpadEnter"
                                                ) {
                                                    if (setSearchType) {
                                                        setSearchType("Search");
                                                    }
                                                }
                                            }}
                                            style={{
                                                width: extraFilter
                                                    ? extraFilter[0]
                                                          .firstWidth - 150
                                                    : "100%",
                                            }}
                                            className="outline-0 p-2 bg-[#F5F0E8]  text-[#4a4a4a]"
                                        />
                                    </div>

                                    <div className=" flex  ">
                                        {extraFilter
                                            ? extraFilter.map((o) => {
                                                  return (
                                                      <div>
                                                          <input
                                                              value={o.value}
                                                              type={"text"}
                                                              placeholder={
                                                                  o.placeHolder
                                                              }
                                                              onChange={(t) => {
                                                                  o.onChange(
                                                                      t.target
                                                                          .value
                                                                  );
                                                              }}
                                                              onKeyDown={(
                                                                  e
                                                              ) => {
                                                                  if (
                                                                      e.code ===
                                                                          "Enter" ||
                                                                      e.code ===
                                                                          "NumpadEnter"
                                                                  ) {
                                                                      if (
                                                                          setSearchType
                                                                      ) {
                                                                          setSearchType(
                                                                              o.placeHolder
                                                                          );

                                                                          o.onChange(
                                                                              e
                                                                                  .target
                                                                                  .value,
                                                                              o.placeHolder
                                                                          );
                                                                      }
                                                                  }
                                                              }}
                                                              style={{
                                                                  width: o.width,
                                                              }}
                                                              className="pl-2 border-l p-2 border-l-[#c7c6c5] bg-[#F5F0E8] outline-0  text-[#4a4a4a]"
                                                          />
                                                      </div>
                                                  );
                                              })
                                            : null}
                                    </div>
                                </div>
                            </div>
                            {windowWidth <= 1280 && windowWidth >= 1024 && (
                                <div className=" h-full border rounded-t-sm   flex flex-col ">
                                    <div className=" w-full flex justify-center  flex-col grow items-center  ">
                                        {/* <img src={tailoringLogo} className="w-44 flex" /> */}
                                        {/* <TailoringxLogo /> */}
                                    </div>

                                    <div className="  justify-end">
                                        <GeniusAlphatecKeyboard
                                            text={searchQuery}
                                            setText={setSearchQuery}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        <div
                            style={{
                                borderTop: "1px dotted black",
                                borderLeft: "1px dotted black",
                                borderRight: "1px dotted black",
                                borderBottom: "1px dotted black",
                                borderBottomLeftRadius: "8px",
                                borderBottomRightRadius: "8px",
                            }}
                            className="w-full flex justify-between p-2 bg-white  "
                        >
                            <div className="text-red">
                                {tips ? (
                                    <div>
                                        <i className="bi bi-lightbulb text-orange mr-3"></i>
                                        {tips}
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                            <button
                                className="bg-brown text-white px-4 py-2 rounded-sm text-sm flex items-center  shadow-sm uppercase font-nunito"
                                onClick={onClose}
                            >
                                <div>back</div>
                            </button>
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
    );
};

export default MasterHelp;
