import React from "react";
import Box from "@mui/material/Box";
import { Outlet, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    selectDefultProfileSetting,
    selectUserType,
} from "../../utility/Redux/profile";
import "react-datepicker/dist/react-datepicker.css";

import AppBarDesign from "../../utility/component/styles/homeStyles";
import CustomeTwoButtonAlert from "../../utility/component/modals/twoButtonAlert";

import SessionOutModal from "../../utility/component/modals/geniusModal";
import AlertModal from "../../utility/component/modals/alert";
import ApprovePost from "./approvePost";
import SpinnerLoading from "../../utility/component/spinnerLoading";
import AlertModal2 from "../../utility/component/alertModal2";

const Home = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const userType = useSelector(selectUserType);
    const defaultProfile = useSelector(selectDefultProfileSetting);

    return (
        <Box className="flex h-screen w-screen overflow-hidden   ">
            <AppBarDesign />
            <div
                style={{ paddingTop: 48 }}
                className=" bg-slate-100 w-screen  overflow-hidden "
            >
                {location.pathname === "/" ? <ApprovePost /> : <Outlet />}
            </div>

            <CustomeTwoButtonAlert />
            <SessionOutModal />
            <AlertModal />
            <SpinnerLoading />
            <AlertModal2 />
        </Box>
    );
};

export default Home;
