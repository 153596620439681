import axios from "axios";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectToken } from "../../utility/Redux/security";
import moment from "moment";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExport,
} from "@mui/x-data-grid";

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <div>
                <div className="print:hidden">
                    <GridToolbarExport
                        printOptions={{
                            hideFooter: true,
                        }}
                    />
                </div>

                <CustomHeader />
            </div>
        </GridToolbarContainer>
    );
}

function CustomHeader() {
    return (
        <>
            <div className="w-full">
                <h1 style={{ fontSize: "15px", fontWeight: "bold" }}>
                    Temple List Master
                </h1>
                <hr></hr>
            </div>
        </>
    );
}

const TempleReport = () => {
    const print_button = {
        width: "8%",
        backgroundColor: "#99CCFF",
        borderRadius: "4px",
        paddingTop: "5px",
        paddingBottom: "5px",
        font: "bold",
    };

    const [rows, setRows] = useState([]);

    const columns = [
        {
            field: "code",
            headerName: "Code",
            width: "50",
            headerAlign: "center",
            align: "left",
        },
        {
            field: "gname",
            headerName: "Gurudwara Name",
            width: "180",
            headerAlign: "center",
            align: "left",
        },
        {
            field: "phone",
            headerName: "Phone",
            headerAlign: "center",
            align: "left",
        },
        {
            field: "address",
            renderCell: (p) => (
                <div>
                    {p.row.address},{p.row.country},{p.row.city},{p.row.state},
                    {p.row.pin_code}
                </div>
            ),
            headerName: "Address",
            headerAlign: "center",
            align: "left",
            width: "220",
        },

        {
            field: "doe",
            valueFormatter: (params) =>
                moment(params?.value).format("DD/MM/YYYY"),
            headerName: "Date Of Est.",
            headerAlign: "center",
            align: "left",
        },

        {
            field: "prd_name",
            headerName: "Prd Name",
            width: "80",
            headerAlign: "center",
            align: "left",
        },
        {
            field: "prd_email",
            headerName: "Prd Email",
            width: "120",
            headerAlign: "center",
            align: "left",
        },
        {
            field: "prd_phone",
            headerName: "Prd Phone",
            width: "80",
            headerAlign: "center",
            align: "left",
        },

        {
            field: "cashr_name",
            headerName: "Cashier Name",
            headerAlign: "center",
            align: "left",
        },
        {
            field: "cashr_email",
            headerName: "Cashier email",
            width: "120",
            headerAlign: "center",
            align: "left",
        },
        {
            field: "cashr_phone",
            headerName: "Cashier Phone",
            flex: 1,
            headerAlign: "center",
            align: "left",
        },
    ];

    const token = useSelector(selectToken);

    const getData = async () => {
        try {
            const response = await axios
                .post(
                    "/api/wcp_masterReports/listTempleMaster",
                    {
                        code: "",
                        gname: "",
                        city: "",
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                .then((p) => {
                    setRows(p.data.response);
                });
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <>
            <div className="px-5 pt-5">
                <DataGrid
                    headerHeight={45}
                    rowHeight={39}
                    showCellRightBorder={true}
                    showColumnRightBorder={true}
                    getRowId={(p) => p.code}
                    style={{
                        width: "100%",
                        height: "530px",
                        margin: "auto",
                        fontSize: "11px",
                    }}
                    rows={rows}
                    columns={columns}
                    sx={{
                        ".MuiDataGrid-columnHeaderTitle": {
                            fontWeight: "bold",
                        },
                    }}
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                />
            </div>
        </>
    );
};

export default TempleReport;
