import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectAppId } from "../../utility/Redux/profile";

const Masters = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const list = [
        {
            name: "Catogery Master",
            path: "",
            userType: "462207993266497",

            onclick: () => {
                navigate("/adminDashboard/catogery_master");
            },
        },
        {
            name: "Section Master",
            path: "",
            userType: "462207993266497",

            onclick: () => {
                navigate("/adminDashboard/section_master");
            },
        },
        {
            name: "Item Master",
            path: "",
            userType: "462207993266497",

            onclick: () => {
                navigate("/adminDashboard/item_master");
            },
        },
    ];

    const app_id = useSelector(selectAppId);
    console.log(app_id);

    return (
        <div className="h-full p-4 text-sm ">
            <div className="grid grid-cols-2 t:grid-cols-3 d:grid-cols-6 ll:grid-cols-6 gap-3  ">
                {list[0] &&
                    list.map((e, i) => {
                        if (e.userType.includes(app_id)) {
                            return (
                                <Button
                                    key={i}
                                    onClick={() => {
                                        if (e.path) {
                                            navigate(e.path);
                                        } else {
                                            e.onclick();
                                        }
                                    }}
                                    variant={"contained"}
                                    style={{
                                        textTransform: "none",
                                        backgroundColor: "transparent",
                                        padding: "0px",
                                        borderRadius: "7px",
                                        overflow: "hidden",
                                    }}
                                >
                                    <div className="bg-[#e9e9e5] py-4 w-full whitespace-nowrap text-[#302d2d]">
                                        {e.name}
                                    </div>
                                </Button>
                            );
                        }
                    })}
            </div>
        </div>
    );
};

export default Masters;
