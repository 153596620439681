import React from "react";
import { Link } from "react-router-dom";
import Nav from "./Nav";
import MobileMenu from "./MobileMenu";
import SearchButton from "./SearchButton";

const HeaderOne = () => {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
        <>
            <header className="main-header clearfix">
                <div className="main-header__top clearfix">
                    <div className="container clearfix">
                        <div className="main-header__top-inner clearfix">
                            <div
                                className="main-header__top-left"
                                style={{
                                    paddingBottom: 0,
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                }}
                            >
                                <ul
                                    style={{ marginBottom: 0 }}
                                    className="list-unstyled main-header__top-address"
                                >
                                    <li>
                                        <div className="icon">
                                            <span className="icon-call"></span>
                                        </div>
                                        <div className="text">
                                            <p>
                                                <a
                                                    style={{
                                                        textDecoration: "none",
                                                    }}
                                                    href="tel:393890463253"
                                                >
                                                    +39 389 046 3253
                                                </a>
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <span className="icon-envelope"></span>
                                        </div>
                                        <div className="text">
                                            <p>
                                                <a
                                                    style={{
                                                        textDecoration: "none",
                                                    }}
                                                    href="mailto:indiansikhcommunityitaly@yahoo.com"
                                                >
                                                    indiansikhcommunityitaly@yahoo.com
                                                </a>
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <span className="icon-place"></span>
                                        </div>
                                        <div className="text">
                                            <p>
                                                Head Office, VIA MALDOFF N, 01 ,
                                                Gavardo, Italy
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="main-header__top-right">
                                <ul className="list-unstyled main-header__top-right-content">
                                    <li>
                                        <Link
                                            to={
                                                process.env.PUBLIC_URL +
                                                `/login`
                                            }
                                        ></Link>
                                    </li>
                                    {/* <li><Link to={process.env.PUBLIC_URL + `/`}>Help You</Link></li> */}
                                    {/* <SearchButton /> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <nav className="main-menu clearfix">
                    <div className="container clearfix">
                        <div className="main-menu-wrapper clearfix">
                            <div className="main-menu-wrapper__left">
                                <div
                                    className="main-menu-wrapper__logo"
                                    style={{
                                        flexDirection: "row",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <Link to={process.env.PUBLIC_URL + `/`}>
                                        <img
                                            src={
                                                publicUrl +
                                                "assets/images/icon/LOGO.jpg"
                                            }
                                            style={{ width: 70, height: 70 }}
                                            alt=""
                                        />
                                    </Link>
                                    <h5
                                        style={{ marginLeft: 10, fontSize: 18 }}
                                    >
                                        Indian Sikh Community Italy
                                    </h5>
                                </div>
                            </div>
                            <div className="main-menu-wrapper__right">
                                <div className="main-menu-wrapper__main-menu">
                                    <MobileMenu />
                                    <Nav />
                                </div>

                                {/* <Link to={process.env.PUBLIC_URL + `/donate-now`} className="thm-btn main-header__btn">Donate Now</Link> */}
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    );
};

export default HeaderOne;
