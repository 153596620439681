import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import WOW from "wowjs";
import { FaAngleUp } from "react-icons/fa";

//Pages
import HomeDefault from "./pages/HomeDefault";
import HomeTwo from "./pages/HomeTwo";
import HomeThree from "./pages/HomeThree";
import About from "./pages/About";
import Team from "./pages/Team";
import TeamDetails from "./pages/TeamDetails";
import Projects from "./pages/Projects";
import ProjectDetails from "./pages/ProjectDetails";
import Testimonials from "./pages/Testimonials";
import Gallery from "./pages/Gallery";
import Donation from "./pages/Donation";
import DonationList from "./pages/DonationList";
import DonationDetails from "./pages/DonationDetails";
import DonateNow from "./pages/DonateNow";
import Events from "./pages/Events";
import BlogVersionOne from "./pages/BlogVersionOne";
import BlogVersionTwo from "./pages/BlogVersionTwo";
import BlogSidebar from "./pages/BlogSidebar";
import BlogDetails from "./pages/BlogDetails";
import Contact from "./pages/Contact";
import Error from "./pages/Error";
import ScrollToTopRoute from "./components/scroll-to-top-route/ScrollToTopRoute";
import MyComponent from "./fb/login";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import FacebookPosts from "./fb/login";

import TestLogin from "./fb/testLogin";

import AdminDashboard from "./pages/adminDashboard";
import AdminLogin from "./pages/admin";
import ProtectedRoute from "./pages/auth/util/protectedRoute";
import Home from "./pages/menu/home";
import ApprovePost from "./pages/menu/approvePost";
import ItemList from "./pages/masters/ItemMaster/list";
import Masters from "./pages/masters/dashBoard";
import CatogeryMaster from "./pages/masters/catogeryMaster/catogery";
import CreatePost from "./pages/menu/createPost";
import ItemMaster from "./pages/masters/ItemMaster/itemMaster";
import SectionMaster from "./pages/masters/SectionMaster/section";
import UpdateKey from "./pages/menu/updateKey";
import AddressMaster from "./pages/menu/addressMaster";
import AddressReport from "./pages/menu/adressReport";
import TempleReport from "./pages/menu/templeReport";
import TempleMaster from "./pages/menu/templeMaster";
function App() {
    useEffect(() => {
        new WOW.WOW({
            live: false,
        }).init();
    }, []);
    return (
        <Router>
            <ScrollToTopRoute />
            <Routes>
                <Route path={`/`} exact element={<HomeDefault />} />
                <Route path={`/fb`} exact element={<MyComponent />} />
                <Route
                    path={`/testFbLoginNode`}
                    exact
                    element={<TestLogin />}
                />
                <Route path={`/fbPost`} exact element={<FacebookPosts />} />
                <Route path={`/home-two`} exact element={<HomeTwo />} />
                <Route path={`/home-three`} exact element={<HomeThree />} />
                <Route path={`/about`} exact element={<About />} />
                <Route path={`/team`} exact element={<Team />} />
                <Route path={`/team-details`} exact element={<TeamDetails />} />
                <Route path={`/projects`} exact element={<Projects />} />
                <Route
                    path={`/project-details`}
                    exact
                    element={<ProjectDetails />}
                />
                <Route
                    path={`/testimonials`}
                    exact
                    element={<Testimonials />}
                />
                <Route path={`/gallery`} exact element={<Gallery />} />
                <Route path={`/donation`} exact element={<Donation />} />
                <Route
                    path={`/donation-list`}
                    exact
                    element={<DonationList />}
                />
                <Route
                    path={`/donation-details`}
                    exact
                    element={<DonationDetails />}
                />
                <Route path={`/donate-now`} exact element={<DonateNow />} />
                <Route path={`/Archive`} exact element={<Events />} />
                <Route path={`/blog-v-1`} exact element={<BlogVersionOne />} />
                <Route path={`/blog-v-2`} exact element={<BlogVersionTwo />} />
                <Route path={`/blog-sidebar`} exact element={<BlogSidebar />} />
                <Route path={`/blog-details`} exact element={<BlogDetails />} />
                <Route path={`/contact`} exact element={<Contact />} />
                <Route path={`/auth/login`} exact element={<AdminLogin />} />
                <Route path={`/*`} exact element={<Error />} />

                <Route
                    path={`/dashboard`}
                    exact
                    element={<AdminDashboard />}
                ></Route>
                <Route
                    path="/adminDashboard"
                    element={
                        <ProtectedRoute>
                            <Home />
                        </ProtectedRoute>
                    }
                >
                    <Route
                        path={`approve_post`}
                        exact
                        element={
                            <ProtectedRoute>
                                <ApprovePost />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path={`item_list`}
                        exact
                        element={
                            <ProtectedRoute>
                                <ItemList />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path={`masters`}
                        exact
                        element={
                            <ProtectedRoute>
                                <Masters />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path={`Catogery_Master`}
                        exact
                        element={
                            <ProtectedRoute>
                                <CatogeryMaster />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path={`create_post`}
                        exact
                        element={
                            <ProtectedRoute>
                                <CreatePost />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path={`item_master`}
                        exact
                        element={
                            <ProtectedRoute>
                                <ItemMaster />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path={`section_master`}
                        exact
                        element={
                            <ProtectedRoute>
                                <SectionMaster />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path={`update_key`}
                        exact
                        element={
                            <ProtectedRoute>
                                <UpdateKey />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="address_master"
                        element={
                            <ProtectedRoute>
                                <AddressMaster />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="address_report"
                        element={
                            <ProtectedRoute>
                                <AddressReport />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="temple_report"
                        element={
                            <ProtectedRoute>
                                <TempleReport />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="temple_master"
                        element={
                            <ProtectedRoute>
                                <TempleMaster />
                            </ProtectedRoute>
                        }
                    />
                </Route>
            </Routes>
            <ScrollToTop
                className="scrollUp"
                smooth
                top="1500"
                component={<FaAngleUp />}
            />
        </Router>
    );
}

export default App;
