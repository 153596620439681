import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../images/ico.png";
import {
    selectCustomeTwoButtomAlert,
    setCustomeTwoButtomAlert,
} from "../../Redux/modal";
import { setLogin, setToken } from "../../Redux/security";
import { useNavigate } from "react-router-dom";

import { resetProfile } from "../../Redux/profile";
import { Button } from "react-bootstrap";

export default function CustomeTwoButtonAlert() {
    const dispatch = useDispatch();
    const status = useSelector(selectCustomeTwoButtomAlert);

    const navigate = useNavigate();
    const myFunction = () => {
        // your logic here
        if (status) {
            dispatch(setToken(""));
            dispatch(setLogin(false));
            navigate("/auth/login");
        }
    };

    useEffect(() => {
        const keyDownHandler = (event) => {
            if (event.key === "Enter") {
                event.preventDefault();

                // 👇️ your logic here
                if (status) {
                    myFunction();
                }
            }
        };

        document.addEventListener("keydown", keyDownHandler);

        return () => {
            document.removeEventListener("keydown", keyDownHandler);
        };
    }, [status]);

    const handleClose = () => {
        dispatch(setCustomeTwoButtomAlert(false));
    };

    return (
        <div className=" rounded-lg">
            <Modal open={status} onClose={handleClose}>
                <div
                    className=" bg-brown overflow-hidden rounded-sm  justify-center  "
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",

                        divShadow: 24,
                    }}
                >
                    <div className=" text-white flex justify-start pl-3 font-normal py-2  bg-[#262424] drop-shadow-xl">
                        genius office
                    </div>
                    <div className="justify-center bg-white min-h-32 pt-4 items-center ">
                        <div className="flex  w-full px-5 pb-6  items-center ">
                            <img
                                src={logo}
                                className="mr-3 w-10 h-10 "
                                alt=" "
                            />
                            <div>
                                <div className="text-brown text-md ">
                                    Are you sure you want to log out ?
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-end pb-3  bg-white pr-3 ">
                            <div className="px-2">
                                <Button
                                    size={"small"}
                                    onClick={() => {
                                        dispatch(setToken(""));
                                        dispatch(setLogin(false));
                                        localStorage.removeItem("stationName");
                                        localStorage.removeItem("stationId");
                                        localStorage.removeItem("billDetails");
                                        localStorage.removeItem("userName");
                                        localStorage.removeItem("count");
                                        localStorage.removeItem("tkn");
                                        localStorage.removeItem("company");
                                        localStorage.removeItem("histryId");
                                        localStorage.removeItem("prefix");
                                        localStorage.removeItem("expiresAt");
                                        localStorage.removeItem(
                                            "orderdItemList"
                                        );
                                        localStorage.removeItem(
                                            "companyDetails"
                                        );
                                        localStorage.removeItem("userType");
                                        localStorage.removeItem("email");

                                        dispatch(resetProfile());
                                        navigate("/auth/login");
                                        dispatch(
                                            setCustomeTwoButtomAlert(false)
                                        );
                                    }}
                                    color="secondary"
                                    variant="contained"
                                    sx={{
                                        transition: "none",
                                        textTransform: "capitalize",
                                    }}
                                >
                                    <div className="px-3">Logout </div>{" "}
                                </Button>
                            </div>

                            <div>
                                <Button
                                    size={"small"}
                                    onClick={handleClose}
                                    color="gray"
                                    variant="contained"
                                    sx={{
                                        transition: "none",
                                        textTransform: "capitalize",
                                    }}
                                >
                                    <div className="px-2">Cancel </div>{" "}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
