import React, { useState } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "../../utility/Redux/security";
import moment from "moment";
import SearchIcon from "@mui/icons-material/Search";
import { parseISO } from "date-fns";
import TempleModal from "../../utility/component/modals/modTempleList";
import {
    setALertStatus,
    setAlertMsg,
    setTempleModalStatus,
} from "../../utility/Redux/modal";

import AlertModal from "../../utility/component/modals/alert";
import { toProperCase } from "../../utility/component/format";

const TempleMaster = () => {
    const [code, setCode] = useState("");
    const [gurudwaraName, setGurudwaraName] = useState("");
    const [address, setAddress] = useState("");
    const [countryName, setCountryName] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [pinCode, setPinCode] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
    const token = useSelector(selectToken);

    const [presidentName, setPresidentName] = useState("");
    const [presidentdob, setPresidentDob] = useState(
        moment().format("YYYY-MM-DD")
    );
    const [presidentbPlace, setPresidentbPlace] = useState("");
    const [presidentEmail, setPresidentEmail] = useState("");
    const [presidentPhone, setPresidentPhone] = useState("");

    const [headgranthiName, setHeadGranthiName] = useState("");
    const [headGranthiEmail, setHeadGranthiEmail] = useState("");
    const [headGranthiPhone, setHeadGranthiPhone] = useState("");

    const [cashierName, setCashierName] = useState("");
    const [cashierEmail, setCashierEmail] = useState("");
    const [cashierPhone, setCashierPhone] = useState("");

    const dispatch = useDispatch();
    const onHandleClick = () => {
        postData();

        setGurudwaraName("");
        setAddress("");
        setCountryName("");
        setState("");
        setCity("");
        setPinCode("");
        setEmail("");
        setPhone("");
        setStartDate(moment().format("YYYY-MM-DD"));

        setPresidentName("");
        setPresidentDob(moment().format("YYYY-MM-DD"));
        setPresidentbPlace("");
        setPresidentEmail("");
        setPresidentPhone("");

        setHeadGranthiName("");
        setHeadGranthiEmail("");
        setHeadGranthiPhone("");

        setCashierName("");
        setCashierEmail("");
        setCashierPhone("");
    };

    const postData = async () => {
        try {
            const response = await axios.post(
                "/api/wcp_saveTempleMaster/saveTempleMaster",
                {
                    gname: gurudwaraName,
                    address: address,
                    country: countryName,
                    state: state,
                    city: city,
                    pin_code: pinCode,
                    email: email,
                    phone: phone,
                    doe: moment(startDate).format("YYYY-MM-DD"),

                    prd_name: presidentName,
                    prd_dob: moment(presidentdob).format("YYYY-MM-DD"),
                    prd_bplace: presidentbPlace,
                    prd_email: presidentEmail,
                    prd_phone: presidentPhone,

                    sec_name: headgranthiName,
                    sec_email: headGranthiEmail,
                    sec_phone: headGranthiPhone,

                    cashr_name: cashierName,
                    cashr_email: cashierEmail,
                    cashr_phone: cashierPhone,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (!response.data.error) {
                handleOpen1();
            } else {
                dispatch(setAlertMsg("something went wrong"));
                dispatch(setALertStatus(true));
            }
            console.log(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const [error, setError] = useState(null);

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const emailValidate = (event) => {
        if (!isValidEmail(event.target.value)) {
            setError("Enter A Valid Email");
        } else {
            setError(null);
        }

        setEmail(event.target.value);
    };

    const [error1, setError1] = useState(null);

    function isValidEmail1(presidentEmail) {
        return /\S+@\S+\.\S+/.test(presidentEmail);
    }

    const prdEmailValidate = (event) => {
        if (!isValidEmail1(event.target.value)) {
            setError1("Enter A Valid Email");
        } else {
            setError1(null);
        }

        setPresidentEmail(event.target.value);
    };

    const [error2, setError2] = useState(null);

    function isValidEmail2(headGranthiEmail) {
        return /\S+@\S+\.\S+/.test(headGranthiEmail);
    }

    const headGEmailValidate = (event) => {
        if (!isValidEmail2(event.target.value)) {
            setError2("Enter A Valid Email");
        } else {
            setError2(null);
        }

        setHeadGranthiEmail(event.target.value);
    };

    const [error3, setError3] = useState(null);

    function isValidEmail3(cashierEmail) {
        return /\S+@\S+\.\S+/.test(cashierEmail);
    }

    const cashirEmailValidate = (event) => {
        if (!isValidEmail3(event.target.value)) {
            setError3("Enter A Valid Email");
        } else {
            setError3(null);
        }

        setCashierEmail(event.target.value);
    };

    const SaveButton = {
        width: "15%",
        backgroundColor: "rgb(156,206,49)",
        borderRadius: "3px",
        paddingTop: "5px",
        paddingBottom: "5px",
        font: "bold",
        height: "38PX",
        fontSize: "14px",
    };

    const ModifyButton = {
        width: "15%",
        backgroundColor: "#99CCFF",
        borderRadius: "3px",
        paddingTop: "5px",
        paddingBottom: "5px",
        font: "bold",
        height: "38PX",
        fontSize: "14px",
        marginLeft: "5px",
    };

    const UndoButton = {
        width: "14%",
        backgroundColor: "rgb(64,64,64)",
        color: "white",
        borderRadius: "3px",
        paddingTop: "5px",
        paddingBottom: "5px",
        font: "bold",
        marginLeft: "3px",
        height: "38PX",
        fontSize: "14px",
    };

    const exit_button = {
        width: "12%",
        backgroundColor: "rgb(224,227,218)",
        borderRadius: "3px",
        paddingTop: "5px",
        paddingBottom: "5px",
        font: "bold",
        marginLeft: "3px",
        height: "38PX",
        fontSize: "14px",
    };

    const handleOpen = () => {
        dispatch(setTempleModalStatus(true));
    };
    const handleClose = () => {
        dispatch(setTempleModalStatus(false));
    };

    const handleOpen1 = () => {
        dispatch(setAlertMsg("Record Saved Successfull."));
        dispatch(setALertStatus(true));
    };

    const clearState = () => {
        setGurudwaraName("");
        setAddress("");
        setCountryName("");
        setState("");
        setCity("");
        setPinCode("");
        setEmail("");
        setPhone("");
        setStartDate(moment().format("YYYY-MM-DD"));

        setPresidentName("");
        setPresidentDob(moment().format("YYYY-MM-DD"));
        setPresidentbPlace("");
        setPresidentEmail("");
        setPresidentPhone("");

        setHeadGranthiName("");
        setHeadGranthiEmail("");
        setHeadGranthiPhone("");

        setCashierName("");
        setCashierEmail("");
        setCashierPhone("");
        setCode("");
    };

    const onHandleUpdate = async () => {
        try {
            await axios.post(
                "/api/wcp_saveTempleMaster/updateTempleMaster",
                {
                    code: code,
                    gname: gurudwaraName,
                    address: address,
                    country: countryName,
                    state: state,
                    city: city,
                    pin_code: pinCode,
                    email: email,
                    phone: phone,
                    doe: moment(startDate).format("YYYY-MM-DD"),

                    prd_name: presidentName,
                    prd_dob: moment(presidentdob).format("YYYY-MM-DD"),
                    prd_bplace: presidentbPlace,
                    prd_email: presidentEmail,
                    prd_phone: presidentPhone,

                    sec_name: headgranthiName,
                    sec_email: headGranthiEmail,
                    sec_phone: headGranthiPhone,

                    cashr_name: cashierName,
                    cashr_email: cashierEmail,
                    cashr_phone: cashierPhone,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            // console.log(response.data);
            clearState();
            dispatch(setAlertMsg("modified succesfully"));
            dispatch(setALertStatus(true));
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <div className="w-full mt-2 max-sm:mt-0 h-full overflow-scroll  ">
                <AlertModal />
                <div className="w-1/2 max-lg:w-full  max-sm:w-full max-xl:w-full  border-2 border-black rounded-md ">
                    <div
                        style={{ backgroundColor: "#e0e0e0" }}
                        className="border rounded-t-md"
                    >
                        <h3 className="w-full text-center border-2 border-gray rounded text-sm font-bold py-3">
                            Gurudwara Information Form
                        </h3>
                    </div>

                    <div>
                        <div class="border-b border-gray-900/10 pb-4 mx-6 max-sm:mx-2">
                            <h2 class="text-sm text-center font-bold leading-7 mt-4 text-gray-900">
                                Gurudwara(s) Information
                            </h2>

                            <div class="col-span-full">
                                <div className="flex">
                                    <label
                                        for="street-address"
                                        class="block text-start text-sm  leading-6 text-gray-900"
                                    >
                                        Gurudwara Name
                                    </label>
                                    <label
                                        style={{
                                            color: "red",
                                            paddingLeft: "1px",
                                        }}
                                    >
                                        *
                                    </label>
                                    <div style={{ marginLeft: "5px" }}>
                                        <SearchIcon
                                            onClick={handleOpen}
                                            style={{ color: "orange" }}
                                        />
                                    </div>
                                </div>
                                <div class="mt-0.5 flex">
                                    <input
                                        value={gurudwaraName}
                                        onChange={(e) => {
                                            setGurudwaraName(
                                                toProperCase(e.target.value)
                                            );
                                        }}
                                        type="text"
                                        name="street-address"
                                        id="street-address"
                                        autocomplete="street-address"
                                        class="block w-full rounded border-0 max-xl:border-1 max-sm:border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset  focus:bg-white ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
                                        placeholder="Enter Gurudwara Name"
                                    />
                                </div>
                            </div>

                            <div>
                                <div className="flex mt-2">
                                    <label
                                        for="email"
                                        class="block text-start text-sm  leading-6 text-gray-900"
                                    >
                                        Address
                                    </label>
                                    <label
                                        style={{
                                            color: "red",
                                            paddingLeft: "1px",
                                        }}
                                    >
                                        *
                                    </label>
                                </div>
                                <div class="mt-0.5 flex">
                                    <input
                                        value={address}
                                        onChange={(e) => {
                                            setAddress(
                                                toProperCase(e.target.value)
                                            );
                                        }}
                                        id="text"
                                        name="email"
                                        type="email"
                                        autocomplete="email"
                                        class="block w-full rounded border-0 max-xl:border-1 px-2 max-sm:border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300   focus:bg-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
                                        placeholder="Enter Address"
                                    />
                                </div>
                            </div>

                            <div class="mt-2 flex">
                                <div className="w-1/2">
                                    <label class="block text-sm text-start leading-6 text-gray-900">
                                        Country
                                    </label>
                                    <div class="mt-0.5">
                                        <input
                                            value={countryName}
                                            onChange={(e) => {
                                                setCountryName(
                                                    toProperCase(e.target.value)
                                                );
                                            }}
                                            type="text"
                                            name="first-name"
                                            id="first-name"
                                            autocomplete="given-name"
                                            class="block w-full px-2 rounded border-0 max-xl:border-1 max-sm:border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300  focus:bg-white placeholder:text-gray-400  focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
                                            placeholder="Enter Country"
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="w-1/2 ml-3 max-sm:ml-1.5">
                                    <label
                                        for="last-name"
                                        class="block text-start text-sm  leading-6 text-gray-900"
                                    >
                                        State
                                    </label>
                                    <div class="mt-0.5">
                                        <input
                                            value={state}
                                            onChange={(e) => {
                                                setState(
                                                    toProperCase(e.target.value)
                                                );
                                            }}
                                            type="text"
                                            name="last-name"
                                            id="last-name"
                                            autocomplete="family-name"
                                            class="block w-full rounded border-0 max-xl:border-1 max-sm:border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300   focus:bg-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
                                            placeholder="Enter Your State"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="mt-2 flex">
                                <div className="w-1/2">
                                    <label class="block text-sm text-start leading-6 text-gray-900">
                                        City
                                    </label>
                                    <div class="mt-0.5">
                                        <input
                                            value={city}
                                            onChange={(e) => {
                                                setCity(
                                                    toProperCase(e.target.value)
                                                );
                                            }}
                                            type="text"
                                            name="first-name"
                                            id="first-name"
                                            autocomplete="given-name"
                                            class="block w-full rounded border-0 max-xl:border-1 max-sm:border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300  focus:bg-white placeholder:text-gray-400  focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
                                            placeholder="Enter Your City"
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="w-1/2 ml-3 max-sm:ml-1.5">
                                    <label
                                        for="last-name"
                                        class="block text-start text-sm  leading-6 text-gray-900"
                                    >
                                        Pin-Code
                                    </label>
                                    <div class="mt-0.5">
                                        <input
                                            value={pinCode}
                                            onChange={(e) => {
                                                setPinCode(
                                                    toProperCase(e.target.value)
                                                );
                                            }}
                                            type="text"
                                            name="last-name"
                                            id="last-name"
                                            autocomplete="family-name"
                                            class="block w-full rounded border-0 max-xl:border-1 max-sm:border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300  focus:bg-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
                                            placeholder="Enter Pin-Code"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="mt-2 flex">
                                <div className="w-1/2">
                                    <label class="block text-sm text-start leading-6 text-gray-900">
                                        Email
                                    </label>
                                    <div class="mt-0.5">
                                        <input
                                            value={email}
                                            onChange={emailValidate}
                                            type="email"
                                            name="first-name"
                                            id="first-name"
                                            autocomplete="given-name"
                                            class="block w-full rounded border-0 max-xl:border-1 max-sm:border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300   focus:bg-white placeholder:text-gray-400  focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
                                            placeholder="Enter Your Email"
                                            required
                                        />
                                    </div>
                                    {error && (
                                        <h3
                                            style={{
                                                color: "red",
                                                fontSize: "15px",
                                            }}
                                        >
                                            {error}
                                        </h3>
                                    )}
                                </div>

                                <div className="w-1/2 ml-3 max-sm:ml-1.5">
                                    <div className="flex">
                                        <label
                                            for="email"
                                            class="block text-start  text-sm  leading-6 text-gray-900"
                                        >
                                            Phone
                                        </label>
                                        <label
                                            style={{
                                                color: "red",
                                                paddingLeft: "1px",
                                            }}
                                        >
                                            *
                                        </label>
                                    </div>
                                    <div class="mt-0.5 flex">
                                        <input
                                            value={phone}
                                            onChange={(e) => {
                                                setPhone(
                                                    toProperCase(e.target.value)
                                                );
                                            }}
                                            type="number"
                                            name="last-name"
                                            id="last-name"
                                            autocomplete="family-name"
                                            class="block w-full rounded border-0 max-xl:border-1 max-sm:border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300   focus:bg-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
                                            placeholder="Enter Your Phone"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="w-1/2 mt-2">
                                <div className="flex">
                                    <label
                                        for="last-name"
                                        class="block text-start text-sm  leading-6 text-gray-900"
                                    >
                                        Date Of Est.
                                    </label>
                                    <label
                                        style={{
                                            color: "red",
                                            paddingLeft: "1px",
                                        }}
                                    >
                                        *
                                    </label>
                                </div>
                                <div class="mt-0.5 flex">
                                    <DatePicker
                                        value={startDate}
                                        className="block w-full rounded border-0 max-xl:border-1 max-sm:border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300  focus:bg-white placeholder:text-gray-400  focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
                                        selected={parseISO(startDate)}
                                        onChange={(date) =>
                                            setStartDate(
                                                moment(date).format(
                                                    "YYYY-MM-DD"
                                                )
                                            )
                                        }
                                    />
                                </div>
                            </div>

                            <hr className="mt-5"></hr>

                            <h2 class="text-sm text-center font-bold leading-7 mt-2 text-gray-900">
                                President Information
                            </h2>

                            <div>
                                <div className="flex">
                                    <label
                                        for="email"
                                        class="block text-start text-sm  leading-6 text-gray-900"
                                    >
                                        President Name
                                    </label>
                                    <label
                                        style={{
                                            color: "red",
                                            paddingLeft: "1px",
                                        }}
                                    >
                                        *
                                    </label>
                                </div>
                                <div class="mt-0.5 flex">
                                    <input
                                        value={presidentName}
                                        onChange={(e) => {
                                            setPresidentName(
                                                toProperCase(e.target.value)
                                            );
                                        }}
                                        id="text"
                                        name="email"
                                        type="email"
                                        autocomplete="email"
                                        class="block w-full rounded border-0 max-xl:border-1 max-sm:border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300  focus:bg-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
                                        placeholder="Enter President Name"
                                    />
                                </div>
                            </div>

                            <div class="mt-2 flex">
                                <div className="w-1/2">
                                    <label class="block text-sm  text-start leading-6 text-gray-900">
                                        Date Of Birth
                                    </label>
                                    <div class="mt-0.5">
                                        <DatePicker
                                            value={presidentdob}
                                            className="block w-full rounded border-0 max-xl:border-1 max-sm:border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300  focus:bg-white placeholder:text-gray-400  focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
                                            selected={parseISO(presidentdob)}
                                            onChange={(date) =>
                                                setPresidentDob(
                                                    moment(date).format(
                                                        "YYYY-MM-DD"
                                                    )
                                                )
                                            }
                                        />
                                    </div>
                                </div>

                                <div className="w-1/2 ml-3 max-sm:ml-1.5">
                                    <label
                                        for="last-name"
                                        class="block text-start text-sm  leading-6 text-gray-900"
                                    >
                                        Place Of Birth
                                    </label>
                                    <div class="mt-0.5">
                                        <input
                                            value={presidentbPlace}
                                            onChange={(e) => {
                                                setPresidentbPlace(
                                                    toProperCase(e.target.value)
                                                );
                                            }}
                                            type="text"
                                            name="last-name"
                                            id="last-name"
                                            autocomplete="family-name"
                                            class="block w-full rounded border-0 max-xl:border-1 max-sm:border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300  focus:bg-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
                                            placeholder="Birth Place"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="mt-2 flex">
                                <div className="w-1/2">
                                    <label class="block text-sm text-start leading-6 text-gray-900">
                                        Email
                                    </label>
                                    <div class="mt-0.5">
                                        <input
                                            value={presidentEmail}
                                            onChange={prdEmailValidate}
                                            type="email"
                                            name="first-name"
                                            id="first-name"
                                            autocomplete="given-name"
                                            class="block w-full rounded border-0 max-xl:border-1 max-sm:border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300   focus:bg-white placeholder:text-gray-400  focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
                                            placeholder="President Email"
                                        />
                                    </div>
                                    {error1 && (
                                        <h3
                                            style={{
                                                color: "red",
                                                fontSize: "15px",
                                            }}
                                        >
                                            {error1}
                                        </h3>
                                    )}
                                </div>

                                <div className="w-1/2 ml-3 max-sm:ml-1.5">
                                    <label
                                        for="last-name"
                                        class="block text-start text-sm  leading-6 text-gray-900"
                                    >
                                        Phone
                                    </label>
                                    <div class="mt-0.5">
                                        <input
                                            value={presidentPhone}
                                            onChange={(e) => {
                                                setPresidentPhone(
                                                    toProperCase(e.target.value)
                                                );
                                            }}
                                            type="number"
                                            name="last-name"
                                            id="last-name"
                                            autocomplete="family-name"
                                            class="block w-full rounded border-0 max-xl:border-1 max-sm:border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300  focus:bg-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
                                            placeholder="Phone No"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            <hr className="mt-5"></hr>

                            <h2 class="text-sm text-center font-bold leading-7 mt-2 text-gray-900">
                                Head Granthi (BABA JI) Information
                            </h2>

                            <div>
                                <label
                                    for="email"
                                    class="block text-start text-sm  leading-6 text-gray-900"
                                >
                                    Head Granthi Name
                                </label>
                                <div class="mt-0.5">
                                    <input
                                        value={headgranthiName}
                                        onChange={(e) => {
                                            setHeadGranthiName(
                                                toProperCase(e.target.value)
                                            );
                                        }}
                                        id="text"
                                        name="email"
                                        type="email"
                                        autocomplete="email"
                                        class="block w-full rounded border-0 max-xl:border-1 max-sm:border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300   focus:bg-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
                                        placeholder="Head Granthi Name"
                                        required
                                    />
                                </div>
                            </div>

                            <div class="mt-2 flex max-sm:block">
                                <div className="w-1/2 max-sm:w-full">
                                    <label class="block text-sm  text-start leading-6 text-gray-900">
                                        Email
                                    </label>
                                    <div class="mt-0.5">
                                        <input
                                            value={headGranthiEmail}
                                            onChange={headGEmailValidate}
                                            type="email"
                                            name="first-name"
                                            id="first-name"
                                            autocomplete="given-name"
                                            class="block w-full px-2 rounded border-0 max-xl:border-1 max-sm:border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300   focus:bg-white placeholder:text-gray-400  focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
                                            placeholder="Head Granthi Email"
                                        />
                                    </div>
                                    {error2 && (
                                        <h3
                                            style={{
                                                color: "red",
                                                fontSize: "15px",
                                            }}
                                        >
                                            {error2}
                                        </h3>
                                    )}
                                </div>

                                <div className="w-1/2 max-sm:w-full ml-3 max-sm:ml-0 ">
                                    <label
                                        for="last-name"
                                        class="block text-start text-sm  leading-6 text-gray-900 max-sm:mt-2"
                                    >
                                        Phone
                                    </label>
                                    <div class="mt-0.5">
                                        <input
                                            value={headGranthiPhone}
                                            onChange={(e) => {
                                                setHeadGranthiPhone(
                                                    toProperCase(e.target.value)
                                                );
                                            }}
                                            type="number"
                                            name="last-name"
                                            id="last-name"
                                            autocomplete="family-name"
                                            class="block w-full rounded border-0 max-xl:border-1 max-sm:border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300   focus:bg-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
                                            placeholder="Head Granthi Phone No"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            <hr className="mt-5"></hr>

                            <h2 class="text-sm text-center font-bold leading-7 mt-2 text-gray-900">
                                Cashier Information
                            </h2>

                            <div>
                                <label
                                    for="email"
                                    class="block text-start text-sm  leading-6 text-gray-900"
                                >
                                    Cashier Name
                                </label>
                                <div class="mt-0.5">
                                    <input
                                        value={cashierName}
                                        onChange={(e) => {
                                            setCashierName(
                                                toProperCase(e.target.value)
                                            );
                                        }}
                                        id="text"
                                        name="email"
                                        type="email"
                                        autocomplete="email"
                                        class="block w-full rounded border-0 max-xl:border-1 max-sm:border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300  focus:bg-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
                                        placeholder="Cashier Name"
                                    />
                                </div>
                            </div>

                            <div class="mt-2 flex max-sm:block">
                                <div className="w-1/2 max-sm:w-full">
                                    <label class="block text-sm text-start leading-6 text-gray-900">
                                        Email
                                    </label>
                                    <div class="mt-0.5">
                                        <input
                                            value={cashierEmail}
                                            onChange={cashirEmailValidate}
                                            type="email"
                                            name="first-name"
                                            id="first-name"
                                            autocomplete="given-name"
                                            class="block  w-full px-2 rounded border-0 max-xl:border-1 max-sm:border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300   focus:bg-white placeholder:text-gray-400  focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
                                            placeholder="Cashier Email"
                                        />
                                    </div>
                                    {error3 && (
                                        <h3
                                            style={{
                                                color: "red",
                                                fontSize: "15px",
                                            }}
                                        >
                                            {error3}
                                        </h3>
                                    )}
                                </div>

                                <div className="w-1/2 max-sm:w-full ml-3 max-sm:ml-0">
                                    <label
                                        for="last-name"
                                        class="block text-start text-sm  leading-6 text-gray-900 max-sm:mt-2"
                                    >
                                        Phone
                                    </label>
                                    <div class="mt-0.5">
                                        <input
                                            value={cashierPhone}
                                            onChange={(e) => {
                                                setCashierPhone(
                                                    toProperCase(e.target.value)
                                                );
                                            }}
                                            type="number"
                                            name="last-name"
                                            id="last-name"
                                            autocomplete="family-name"
                                            class="block w-full rounded border-0 max-xl:border-1 max-sm:border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300  focus:bg-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
                                            placeholder="Cashier Phone No"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="flex w-full mt-6 justify-end">
                                {code ? (
                                    <button
                                        onClick={onHandleUpdate}
                                        style={
                                            window.innerWidth <= 500
                                                ? {
                                                      ...ModifyButton,
                                                      width: "25%",
                                                  }
                                                : ModifyButton
                                        }
                                    >
                                        Modify
                                    </button>
                                ) : (
                                    <button
                                        onClick={onHandleClick}
                                        style={
                                            window.innerWidth <= 500
                                                ? {
                                                      ...SaveButton,
                                                      width: "25%",
                                                  }
                                                : SaveButton
                                        }
                                    >
                                        Save
                                    </button>
                                )}
                                <button
                                    onClick={clearState}
                                    style={
                                        window.innerWidth <= 500
                                            ? {
                                                  ...UndoButton,
                                                  width: "25%",
                                              }
                                            : UndoButton
                                    }
                                >
                                    Undo
                                </button>
                                <button
                                    onClick={clearState}
                                    style={
                                        window.innerWidth <= 500
                                            ? {
                                                  ...exit_button,
                                                  width: "25%",
                                              }
                                            : exit_button
                                    }
                                >
                                    Exit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <TempleModal
                    setGurudwaraName={setGurudwaraName}
                    setAddress={setAddress}
                    setCountryName={setCountryName}
                    setState={setState}
                    setCity={setCity}
                    setPinCode={setPinCode}
                    setEmail={setEmail}
                    setPhone={setPhone}
                    setPresidentName={setPresidentName}
                    setPresidentbPlace={setPresidentbPlace}
                    setPresidentEmail={setPresidentEmail}
                    setPresidentPhone={setPresidentPhone}
                    setCashierName={setCashierName}
                    setCashierEmail={setCashierEmail}
                    setCashierPhone={setCashierPhone}
                    setStartDate={setStartDate}
                    setPresidentDob={setPresidentDob}
                    setHeadGranthiName={setHeadGranthiName}
                    setHeadGranthiEmail={setHeadGranthiEmail}
                    setHeadGranthiPhone={setHeadGranthiPhone}
                    handleClose={handleClose}
                    code={code}
                    setCode={setCode}
                />
            </div>
        </>
    );
};

export default TempleMaster;
