import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BsSquare, BsCheckSquare } from "react-icons/bs";
import logo from "../utility/images/WGLOGO.png";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import { setDevloperMode, setLogin, setToken } from "../utility/Redux/security";
import { useDispatch, useSelector } from "react-redux";
import { useFocus } from "../utility/hooks/hooks";
import {
    setAppId,
    setCompany,
    setCompanyAddress,
    setPageId,
    setPrefix,
    setUserName,
} from "../utility/Redux/profile";
import {
    selectStatus,
    setAlertMsg,
    setALertStatus,
    setCheckEmailAlert,
    setloading,
    setSpinnerLoading,
} from "../utility/Redux/modal";
import AlertModal from "../utility/component/modals/alert";
import { BarLoader } from "react-spinners";
import CheckEmailModall from "../utility/component/modals/resetPassword";

import EmailVerify from "../utility/component/modals/verifyEmailModal";

import EmailVerified from "../utility/component/modals/emailVerified";
import moment from "moment";
import CryptoJS from "crypto-js";
import SpinnerLoading from "../utility/component/spinnerLoading";
import LoginUi from "./login";

const Login = () => {
    const [remember, setRember] = useState(false);
    const [passRef, setPasswordFocus] = useFocus();
    const [hidden, sethidden] = useState(true);
    const [email, setemail] = useState("");
    const [password, setpassword] = useState("");
    const loading = useSelector(selectStatus);
    const [forget, setForget] = useState(false);
    const move = useNavigate();

    const location = useLocation();

    const secretPass = process.env.REACT_APP_SECRET_KEY;

    const encryptData = (text) => {
        const data = CryptoJS.AES.encrypt(
            JSON.stringify(text),
            secretPass
        ).toString();

        localStorage.setItem("prefix", data);
    };
    const encryptAppid = (text) => {
        const data = CryptoJS.AES.encrypt(
            JSON.stringify(text),
            secretPass
        ).toString();

        localStorage.setItem("app", data);
    };

    const encryptPageid = (text) => {
        const data = CryptoJS.AES.encrypt(
            JSON.stringify(text),
            secretPass
        ).toString();

        localStorage.setItem("page", data);
    };

    const encryptUserType = (text) => {
        const data = CryptoJS.AES.encrypt(
            JSON.stringify(text),
            secretPass
        ).toString();

        localStorage.setItem("userType", data);
    };

    const storeRememberData = async (email, passwrd) => {
        const e = CryptoJS.AES.encrypt(
            JSON.stringify(email),
            secretPass
        ).toString();
        const p = CryptoJS.AES.encrypt(
            JSON.stringify(passwrd),
            secretPass
        ).toString();

        localStorage.setItem(
            "rememberd",
            JSON.stringify({
                email: e,
                password: p,
            })
        );
    };

    // const signupData = location.state
    const override = {
        display: "block",
        margin: "0 auto",
        top: 0,
        width: "auto",
    };
    const initialValues = {
        Email: "",
        login_password: "",
    };

    const headers = {
        "Content-Type": "application/json",
    };
    const dispatch = useDispatch();
    useEffect(() => {
        const isRemmberd = JSON.parse(localStorage.getItem("rememberd"));

        if (isRemmberd) {
            const bytes = CryptoJS.AES.decrypt(isRemmberd.email, secretPass);
            const bytes2 = CryptoJS.AES.decrypt(
                isRemmberd.password,
                secretPass
            );

            if (bytes.sigBytes < 0 || bytes2.sigBytes < 0) {
                localStorage.removeItem("rememberd");
            } else {
                const decEmail = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                const decPass = JSON.parse(bytes2.toString(CryptoJS.enc.Utf8));

                setemail(decEmail);
                setpassword(decPass);
                setRember(true);
            }
        }
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => sethidden(true), 1000);
        return () => clearTimeout(timer);
    }, [hidden]);

    useEffect(() => {
        localStorage.removeItem("stationName");
        localStorage.removeItem("stationId");
        localStorage.removeItem("billDetails");
        localStorage.removeItem("userName");
        localStorage.removeItem("count");
        localStorage.removeItem("tkn");
        localStorage.removeItem("company");
        localStorage.removeItem("histryId");
        localStorage.removeItem("prefix");
        localStorage.removeItem("expiresAt");
        localStorage.removeItem("orderdItemList");
        localStorage.removeItem("userType");
        localStorage.removeItem("companyDetails");
    }, []);

    const checkTodayAccess = (userDetail, response) => {
        if (
            moment(userDetail.end_date).format("YYYY-MM-DD") >=
            moment().format("YYYY-MM-DD")
        ) {
            if (userDetail.user_email === email) {
                if (userDetail.table_prefix !== null) {
                    if (userDetail.user_status === "ACTIVE") {
                        encryptEmail(email);

                        localStorage.setItem(
                            "expiresAt",
                            new Date().getTime() + 60 * 60000
                        );

                        dispatch(setToken(response.data.token));

                        dispatch(setLogin(true));
                        dispatch(setUserName(response.data.data[0].user_name));
                        dispatch(
                            setCompany(response.data.data[0].company_name)
                        );
                        dispatch(setPrefix(response.data.data[0].table_prefix));
                        dispatch(setAppId(userDetail.app_id));
                        dispatch(setPageId(userDetail.page_id));
                        encryptAppid(userDetail.app_id);
                        encryptPageid(userDetail.page_id);
                        localStorage.setItem(
                            "companyDetails",
                            JSON.stringify({
                                address: userDetail.address,
                                city: userDetail.city,
                                state: userDetail.state,
                                mobile: userDetail.company_mobile,
                                email: userDetail.company_email,
                                companyName: userDetail.company_name,
                                companyWebsite: userDetail.company_website,
                            })
                        );

                        dispatch(
                            setCompanyAddress({
                                address: userDetail.address,
                                city: userDetail.city,
                                state: userDetail.state,
                                mobile: userDetail.company_mobile,
                                email: userDetail.company_email,
                                companyName: userDetail.company_name,
                                companyWebsite: userDetail.company_website,
                            })
                        );

                        encryptData(response.data.data[0].table_prefix);
                        encryptUserType(response.data.data[0].user_type);

                        localStorage.setItem(
                            "company",
                            response.data.data[0].company_name
                        );
                        localStorage.setItem(
                            "userName",
                            response.data.data[0].user_name
                        );

                        localStorage.removeItem("lastDate");
                        if (remember) {
                            storeRememberData(email, password);
                        } else {
                            localStorage.removeItem("rememberd");
                        }
                        dispatch(setSpinnerLoading(false));
                        move("/adminDashboard", {
                            state: {
                                sid: response.data.data[0].fb_id,
                            },
                        });
                    } else {
                        dispatch(
                            setAlertMsg(
                                "Access to your account has been restricted. Please contact the admin to activate your account."
                            )
                        );
                        dispatch(setALertStatus(true));
                    }
                } else {
                    dispatch(
                        setAlertMsg(
                            "Crediantials Issue Kindly Contact Support Team"
                        )
                    );
                    dispatch(setALertStatus(true));
                }

                //  localStorage.setItem('token',  response.data.token );
            } else {
                dispatch(setAlertMsg("Email does not exist"));
                dispatch(setALertStatus(true));
            }
        } else {
            dispatch(
                setAlertMsg(
                    "Your License has Expired.Please Contact support for renewal."
                )
            );
            dispatch(setALertStatus(true));
        }
    };
    console.log(process.env);

    const loginApi = () => {
        let re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (re.test(email)) {
            const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;
            // const hmac = CryptoJS.HmacSHA256(message, secret);
            const hmac = CryptoJS.HmacSHA256(email, secretPass);
            // Convert the HMAC to a string and encode it using Base64
            const hmacString = hmac.toString(CryptoJS.enc.Base64);

            if (password.length >= 5) {
                dispatch(setSpinnerLoading("Checking Crediantials"));
                axios
                    .post(
                        `/api/wcp_usersRoute/login`,
                        {
                            user_email: email,
                            user_password: password,
                        },
                        {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${hmacString}`,
                            },
                        }
                    )
                    .then(async function (response) {
                        if (response !== null) {
                            if (response.data.error) {
                                if (
                                    response.data.error ===
                                    "Invalid Email or Password."
                                ) {
                                    dispatch(setAlertMsg(response.data.error));
                                    dispatch(setALertStatus(true));
                                } else {
                                    axios
                                        .post(
                                            `/api/wcp_usersRoute/userInfo`,
                                            {
                                                user_email: email,
                                            },
                                            {
                                                headers: {
                                                    "Content-Type":
                                                        "application/json",
                                                    Authorization: `Bearer ${hmacString}`,
                                                },
                                            }
                                        )
                                        .then((res) => {
                                            console.log(res);
                                            const userDetail =
                                                res.data.response[0];
                                            if (
                                                userDetail.email_verified ===
                                                "N"
                                            ) {
                                                dispatch(
                                                    setAlertMsg(
                                                        "Your account email needs to be verified. Please check your inbox for a verification link"
                                                    )
                                                );
                                                dispatch(setALertStatus(true));
                                            } else {
                                                dispatch(
                                                    setAlertMsg(
                                                        "Thank you for registering. Your account is currently being processed. You will be able to login once your account has been activated."
                                                    )
                                                );
                                                dispatch(setALertStatus(true));
                                            }
                                        })
                                        .catch((err) => {
                                            console.log(err);
                                            dispatch(
                                                setAlertMsg(
                                                    "Email does not exist or invalid details."
                                                )
                                            );
                                            dispatch(setALertStatus(true));
                                        });
                                }

                                // dispatch(setAlertMsg(response.data.error))
                                // dispatch(setALertStatus(true))
                            } else {
                                dispatch(
                                    setSpinnerLoading("Loading User Details")
                                );
                                axios
                                    .post(
                                        `/api/wcp_usersRoute/userInfo`,
                                        {
                                            user_email: email,
                                        },
                                        {
                                            headers: {
                                                "Content-Type":
                                                    "application/json",
                                                Authorization: `Bearer ${hmacString}`,
                                            },
                                        }
                                    )
                                    .then((res) => {
                                        const userDetail = res.data.response[0];
                                        console.log(userDetail);
                                        if (userDetail.developer_yn === "Y") {
                                            dispatch(setDevloperMode(true));
                                        }
                                        checkTodayAccess(userDetail, response);
                                    });
                            }
                        } else {
                            dispatch(setAlertMsg("Email does not exist"));
                            dispatch(setALertStatus(true));
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        dispatch(setAlertMsg(error.code + " " + error.message));
                        dispatch(setALertStatus(true));
                    })
                    .finally(() => {
                        dispatch(setloading(false));
                    });
            } else {
                if (password) {
                    dispatch(setALertStatus("Email does not exist"));
                    dispatch(setALertStatus(true));
                } else {
                    dispatch(setAlertMsg("Write password"));
                    dispatch(setALertStatus(true));
                }
            }
        } else {
            // invalid email, maybe show an error to the user.

            dispatch(setAlertMsg("Invailed email address"));
            dispatch(setALertStatus(true));
        }
    };

    const forgetModel = (e) => {
        let re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (re.test(e)) {
            setForget(true);
        } else {
            dispatch(
                setAlertMsg(
                    "Enter valid user email address for change password"
                )
            );
            dispatch(setALertStatus(true));
        }
    };

    const forgetPassword = (emailCheck) => {
        const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;

        // const hmac = CryptoJS.HmacSHA256(message, secret);
        const hmac = CryptoJS.HmacSHA256(email, secretPass);
        // Convert the HMAC to a string and encode it using Base64
        const hmacString = hmac.toString(CryptoJS.enc.Base64);
        let re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (re.test(emailCheck)) {
            dispatch(setloading(true));

            axios
                .post(
                    "/api/wcp_usersRoute/findUserDetail",
                    {
                        user_email: emailCheck,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${hmacString}`,
                        },
                    }
                )
                .then((res) => {
                    if (res.data[0]) {
                        axios
                            .post(
                                `/api/wcp_usersRoute/resetUserPasswordEmail`,
                                {
                                    user_email: emailCheck,
                                    user_name: res.data[0].user_name,
                                },
                                {
                                    headers: {
                                        "Content-Type": "application/json",
                                        Authorization: `Bearer ${hmacString}`,
                                    },
                                }
                            )
                            .then((r) => {
                                dispatch(setCheckEmailAlert(true));
                                // move('/auth/checkEmail')
                            })
                            .catch((err) => {
                                dispatch(
                                    setAlertMsg(err.code + " " + err.message)
                                );
                                dispatch(setALertStatus(true));
                            })
                            .finally(() => {
                                dispatch(setloading(false));
                            });
                    } else {
                        dispatch(setAlertMsg("Not registered user"));
                        dispatch(setALertStatus(true));
                    }
                })
                .catch((e) => {
                    console.log(e);
                    dispatch(setAlertMsg("Something wrong"));
                    dispatch(setALertStatus(true));
                });
        } else {
            dispatch(setAlertMsg("Invailed email address"));
            dispatch(setALertStatus(true));
        }
    };

    const encryptEmail = (email) => {
        const data = CryptoJS.AES.encrypt(
            JSON.stringify(email),
            secretPass
        ).toString();

        localStorage.setItem("email", data);
    };

    return (
        <div>
            <LoginUi
                location={location}
                BsCheckSquare={BsCheckSquare}
                setemail={setemail}
                passRef={passRef}
                hidden={hidden}
                VisibilityIcon={VisibilityIcon}
                sethidden={sethidden}
                VisibilityOffIcon={VisibilityOffIcon}
                remember={remember}
                BsSquare={BsSquare}
                setRember={setRember}
                loginApi={loginApi}
                forgetModel={forgetModel}
                dispatch={dispatch}
                setAlertMsg={setAlertMsg}
                setALertStatus={setALertStatus}
                move={move}
                BarLoader={BarLoader}
                EmailVerify={EmailVerify}
                EmailVerified={EmailVerified}
                loading={loading}
                override={override}
                initialValues={initialValues}
                setPasswordFocus={setPasswordFocus}
                password={password}
                setpassword={setpassword}
                AlertModal={AlertModal}
                forget={forget}
                setForget={setForget}
                forgetPassword={forgetPassword}
                CheckEmailModall={CheckEmailModall}
                logo={logo}
                email={email}
            />
            <SpinnerLoading />
        </div>
    );
};
export default Login;
