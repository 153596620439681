import React, { useEffect } from "react";
import HeaderOne from "../common/header/HeaderOne";
import HeroOne from "../components/hero/HeroOne";
import CounterTwo from "../components/counter/CounterTwo";
import FeatureTwo from "../components/features/FeatureOne";
import AboutOne from "../components/about/AboutOne";
import CausesFour from "../components/causes/CausesFour";
import ProjectOne from "../components/project/ProjectOne";
import BrandTwo from "../components/brand/BrandTwo";
import SkillOne from "../components/skills/SkillOne";
import TeamOne from "../components/team/TeamOne";
import VideoOne from "../components/video/VideoOne";
import TestimonialOne from "../components/testimonial/TestimonialOne";
import BlogSectionThree from "../components/blog/BlogSectionThree";
import FooterOne from "../common/footer/FooterOne";
import BlogSectionTwo from "../components/blog/BlogSectionTwo";
import BlogLatestPost from "../components/blog-sidebar/BlogLatestPost";
import BlogCategory from "../components/blog-sidebar/BlogCategory";
import HeroTwo from "../components/hero/HeroTwo";
import HeroThree from "../components/hero/HeroThree";
import HeaderTwo from "../common/header/HeaderTwo";
import AboutTwo from "../components/about/AboutTwo";
import CounterOne from "../components/counter/CounterOne";
import { CountertopsOutlined } from "@mui/icons-material";

const HomeDefault = () => {
  return (
    <>
      <HeaderOne />

      <HeroOne />
      <CounterTwo />
      <BlogSectionThree />
      <FeatureTwo />
      <AboutOne />
      {/* <CausesFour /> */}

      {/* work on project one later */}
      {/* <ProjectOne /> */}
      {/* <BrandTwo /> */}
      {/* <SkillOne /> */}
      <TeamOne />
      {/* <VideoOne /> */}
      {/* <TestimonialOne /> */}
      {/* <BlogSectionThree /> */}
      <FooterOne />
    </>
  );
};

export default HomeDefault;
