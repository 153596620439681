import React from "react";
import { Card, CardBody, FormGroup, Input, Label } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import CardTitle from "../../../utility/cardTitle";
import MultyImageUploader from "../../../utility/component/multyImage";

const GeneralItemDetails = ({
    titleStyles,
    supplierList,
    brandList,
    sectionList,
    itemData,
    setItemData,
    catogeryList,
    colorList,
    selecedFile,
    setSelectedFile,
    selecedFile2,
    setSelectedFile2,
    selecedFile3,
    setSelectedFile3,
    selecedFile4,
    setSelectedFile4,
    setItemMasterHelpStatus,
}) => {
    return (
        <Card className="w-full  p-4">
            <CardTitle title="General" />
            <div className="mt-4">
                <Label className={titleStyles}> Product Upload</Label>

                <div>
                    <MultyImageUploader
                        selecedFile={selecedFile}
                        setSelectedFile={setSelectedFile}
                        selecedFile2={selecedFile2}
                        setSelectedFile2={setSelectedFile2}
                        selecedFile3={selecedFile3}
                        setSelectedFile3={setSelectedFile3}
                        selecedFile4={selecedFile4}
                        setSelectedFile4={setSelectedFile4}
                        itemData={itemData}
                        setItemData={setItemData}
                    />
                </div>

                <FormGroup className="mt-4 ">
                    <Label
                        for="validationCustom02"
                        className={titleStyles + "flex items-center "}
                    >
                        <span className="">*</span> Item Name
                        <button
                            onClick={() => {
                                setItemMasterHelpStatus(true);
                            }}
                            className="ml-2"
                        >
                            <i className="bi bi-search text-orange"></i>
                        </button>
                    </Label>
                    <Input
                        value={itemData.ITEMNAME}
                        onChange={(e) =>
                            setItemData((o) => {
                                return {
                                    ...o,
                                    ITEMNAME: e.target.value,
                                };
                            })
                        }
                        id="validationCustom02"
                        type="text"
                        required
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="validationCustom02" className={titleStyles}>
                        <span className="">*</span> Alias Code
                    </Label>
                    <Input
                        value={itemData.ALIAS_CODE}
                        onChange={(e) =>
                            setItemData((o) => {
                                return {
                                    ...o,
                                    ALIAS_CODE: e.target.value,
                                };
                            })
                        }
                        id="validationCustom02"
                        type="text"
                        required
                    />
                </FormGroup>

                <FormGroup>
                    <Label className={titleStyles}>
                        <span>*</span> Categories
                    </Label>
                    <select
                        value={itemData.CATEGORY}
                        onChange={(e) =>
                            setItemData((o) => {
                                return {
                                    ...o,
                                    CATEGORY: e.target.value,
                                };
                            })
                        }
                        className="form-select"
                        required
                    >
                        <option value="">--Select--</option>
                        {catogeryList.map((o, i) => {
                            return (
                                <option key={i} value={o.CATEGORY}>
                                    {" "}
                                    {o.CATEGORY}{" "}
                                </option>
                            );
                        })}
                    </select>
                </FormGroup>

                <FormGroup>
                    <Label className={titleStyles}>
                        <span>*</span> Section
                    </Label>
                    <select
                        value={itemData.SECTION_NAME}
                        onChange={(e) =>
                            setItemData((o) => {
                                return {
                                    ...o,
                                    SECTION_NAME: e.target.value,
                                };
                            })
                        }
                        className="form-select"
                        required
                    >
                        <option value="">--Select--</option>
                        {sectionList.map((o, i) => {
                            return (
                                <option key={i} value={o.SECTION_NAME}>
                                    {" "}
                                    {o.SECTION_NAME}{" "}
                                </option>
                            );
                        })}
                    </select>
                </FormGroup>

                <FormGroup>
                    <Label className={titleStyles}>
                        <span>*</span> Color
                    </Label>
                    <select
                        value={itemData.COLOR}
                        onChange={(e) =>
                            setItemData((o) => {
                                return {
                                    ...o,
                                    COLOR: e.target.value,
                                };
                            })
                        }
                        className="form-select"
                        required
                    >
                        <option value="">--Select--</option>

                        {colorList.map((o, i) => {
                            return (
                                <option key={i} value={o.COLOR}>
                                    {" "}
                                    {o.COLOR}{" "}
                                </option>
                            );
                        })}
                    </select>
                </FormGroup>

                {/* <FormGroup>
            <Label className={titleStyles}>
              <span>*</span> Size
            </Label>
            <div className="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
              <Label className="d-block">
                <Input onChange={(e)=>{
if(e.target.checked){
  setItemData(o=>{
    return{
      ...o,
      SIZE:"Small"
    }
  })
}
                }} checked={itemData.SIZE ==='Small'} className="radio_animated mr-2" type="radio" />
                Small
              </Label>
              <Label className="d-block ml-5">
                <Input onChange={(e)=>{
if(e.target.checked){
  setItemData(o=>{
    return{
      ...o,
      SIZE:"Medium"
    }
  })
}}} checked={itemData.SIZE ==='Medium'} className="radio_animated mr-2"  type="radio"  />
                Medium
              </Label>
            </div>
          </FormGroup> */}

                <FormGroup>
                    <Label className={titleStyles}>
                        <span>*</span> Unit
                    </Label>
                    <select
                        value={itemData.UNIT}
                        onChange={(e) =>
                            setItemData((o) => {
                                return {
                                    ...o,
                                    UNIT: e.target.value,
                                };
                            })
                        }
                        className="form-select"
                        required
                    >
                        <option value="">--Select--</option>
                        <option value="KG">KG</option>
                        <option value="GRM">GRM</option>
                        <option value="PCS">PCS</option>
                    </select>
                </FormGroup>

                <FormGroup className="mt-4">
                    <Label for="validationCustom02" className={titleStyles}>
                        <span className="">*</span> Packing
                    </Label>
                    <Input
                        value={itemData.PACKING}
                        onChange={(e) =>
                            setItemData((o) => {
                                return {
                                    ...o,
                                    PACKING: e.target.value,
                                };
                            })
                        }
                        id="validationCustom02"
                        type="text"
                        required
                    />
                </FormGroup>

                <FormGroup>
                    <Label className={titleStyles}>
                        <span>*</span> Brand
                    </Label>
                    <Input
                        value={itemData.BRAND}
                        onChange={(e) =>
                            setItemData((o) => {
                                return {
                                    ...o,
                                    BRAND: e.target.value,
                                };
                            })
                        }
                        id="validationCustom02"
                        type="text"
                        required
                    />
                </FormGroup>

                <FormGroup>
                    <Label className={titleStyles}>
                        <span>*</span> Supplier Name
                    </Label>
                    <Input
                        value={itemData.SUPPLIER_NAME}
                        onChange={(e) =>
                            setItemData((o) => {
                                return {
                                    ...o,
                                    SUPPLIER_NAME: e.target.value,
                                };
                            })
                        }
                        id="validationCustom02"
                        type="text"
                        required
                    />
                </FormGroup>

                <FormGroup>
                    <Label className={titleStyles}>
                        <span>*</span> Stop
                    </Label>
                    <div className="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                        <Label className="d-block">
                            <Input
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setItemData((o) => {
                                            return {
                                                ...o,
                                                STOP_ITEM: "YES",
                                            };
                                        });
                                    }
                                }}
                                checked={itemData.STOP_ITEM === "YES"}
                                className="radio_animated mr-2"
                                type="radio"
                            />
                            Yes
                        </Label>
                        <Label className="d-block ml-5">
                            <Input
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setItemData((o) => {
                                            return {
                                                ...o,
                                                STOP_ITEM: "NO",
                                            };
                                        });
                                    }
                                }}
                                checked={itemData.STOP_ITEM === "NO"}
                                className="radio_animated mr-2"
                                type="radio"
                            />
                            No
                        </Label>
                    </div>
                </FormGroup>
            </div>
        </Card>
    );
};

export default GeneralItemDetails;
