import React from "react";
import Modal from "@mui/material/Modal";
import Lottie from "lottie-react";
import { useSelector } from "react-redux";
import { selectSpinnerLoading, selectSpinnerText } from "../Redux/modal";
import spinner from "../images/spnr.json";

const width = window.innerWidth / 100;

export default function SpinnerLoading() {
    const loading = useSelector(selectSpinnerLoading);
    const text = useSelector(selectSpinnerText);

    const modalStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "auto",
        height: "auto",
        boxShadow: 24,
        borderRadius: "7px",
        outline: "none",
        backgroundColor: "white",
        padding: "1rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    };

    const contentStyle = {
        fontSize: "13px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        color: "#1c1a1a",
        gap: "8px",
    };

    const lottieStyle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "18px",
        height: "18px",
        marginTop: "5px",
    };

    return (
        <div>
            <Modal open={loading}>
                <div style={modalStyle}>
                    <div style={contentStyle}>
                        <div style={lottieStyle}>
                            <Lottie animationData={spinner} loop={true} />
                        </div>
                        <div>{text}</div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
