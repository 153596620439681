import { configureStore } from "@reduxjs/toolkit";
import facebook from "./facebook";
import modal from "./modal";
import refresh from "./refresh";
import profile from "./profile";
import securityReducer from "./security";

const Store = configureStore({
    reducer: {
        facebook: facebook,
        security: securityReducer,
        modals: modal,
        refresh: refresh,
        profile: profile,
    },
});

export default Store;
