import { Button, ButtonBase, Modal } from "@mui/material";
import React, { useState } from "react";

const LoginUi = ({
    setemail,
    location,
    passRef,
    setRember,
    loginApi,
    forgetModel,
    dispatch,
    setAlertMsg,
    setALertStatus,
    AlertModal,
    initialValues,
    forget,
    setForget,
    forgetPassword,
    CheckEmailModall,
    logo,
    email,
    EmailVerify,
    EmailVerified,
    setPasswordFocus,
    password,
    setpassword,
    remember,
}) => {
    const windowWiidt = window.innerWidth;
    const [emailBordered, setEmailLoginBordered] = useState("gray");
    const [PasswordBordered, setPasswordBordered] = useState("gray");

    return (
        <div
            style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
            }}
        >
            <div>
                <div style={{ display: "flex" }}>
                    <div
                        style={{
                            width: "200px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            <img src={logo} style={{ width: "150px" }} />
                        </div>
                    </div>

                    <div>
                        <div
                            style={{
                                borderLeft: "1px solid #ccc",
                                padding: "20px",
                            }}
                        >
                            <div
                                style={{ fontSize: "19px", fontWeight: "bold" }}
                            >
                                Login -{" "}
                                <label style={{ fontWeight: "bold" }}>
                                    {" "}
                                    Control Panel
                                </label>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginTop: "12px",
                                    alignItems: "center",
                                }}
                            >
                                <div
                                    style={{
                                        width:
                                            windowWiidt >= 768
                                                ? "300px"
                                                : "300px",
                                    }}
                                >
                                    <div
                                        style={{
                                            border: `1px solid ${emailBordered}`,
                                            borderRadius: "3px",
                                            overflow: "hidden",
                                        }}
                                    >
                                        <input
                                            autoSave={initialValues.Email}
                                            onFocus={() =>
                                                setEmailLoginBordered("#211adb")
                                            }
                                            onBlur={() =>
                                                setEmailLoginBordered("gray")
                                            }
                                            size="small"
                                            onKeyDown={(event) => {
                                                if (event.key === "Enter") {
                                                    setPasswordFocus();
                                                }
                                            }}
                                            value={email}
                                            style={{
                                                fontSize: "13px",
                                                borderRadius: "3px",
                                                overflow: "hidden",
                                                width: "100%",
                                                padding: "8px 12px",
                                                outline: "none",
                                                border: "0px solid gray",
                                            }}
                                            placeholder="Email Address"
                                            name="Email"
                                            id="Email"
                                            onChange={(t) =>
                                                setemail(
                                                    t.target.value.toLowerCase()
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginTop: "12px",
                                    alignItems: "center",
                                }}
                            >
                                <div
                                    style={{
                                        width:
                                            windowWiidt >= 768
                                                ? "300px"
                                                : "300px",
                                    }}
                                >
                                    <div
                                        style={{
                                            border: `0.5px solid ${PasswordBordered}`,
                                            borderRadius: "3px",
                                            overflow: "hidden",
                                        }}
                                    >
                                        <input
                                            autoSave={initialValues.Email}
                                            onFocus={() =>
                                                setPasswordBordered("#211adb")
                                            }
                                            onBlur={() =>
                                                setPasswordBordered("gray")
                                            }
                                            size="small"
                                            type="password"
                                            ref={passRef}
                                            value={password}
                                            style={{
                                                fontSize: "13px",
                                                borderRadius: "3px",
                                                overflow: "hidden",
                                                width: "100%",
                                                padding: "8px 12px",
                                                outline: "none",
                                                border: "0px solid gray",
                                            }}
                                            name="login_password"
                                            id="login_password"
                                            placeholder="Password"
                                            onChange={(p) =>
                                                setpassword(p.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: "flex", marginTop: "8px" }}>
                                <input
                                    checked={remember}
                                    type="checkbox"
                                    onChange={(e) =>
                                        setRember(e.target.checked)
                                    }
                                />
                                <div
                                    style={{
                                        fontSize: "12px",
                                        marginLeft: "8px",
                                    }}
                                >
                                    Remember me
                                </div>
                            </div>
                            <div style={{ marginTop: "12px" }}>
                                <Button
                                    onClick={loginApi}
                                    variant="contained"
                                    style={{
                                        textTransform: "none",
                                        letterSpacing: "1px",
                                        backgroundColor: "#383535",
                                        width: "100%",
                                        height: "36px",
                                    }}
                                >
                                    <div
                                        style={{ fontSize: "14px" }}
                                        className="text-white font-sans whitespace-nowrap"
                                    >
                                        Login
                                    </div>
                                </Button>
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    marginTop: "20px",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    <div
                                        onClick={() => {
                                            if (email) {
                                                forgetModel(email);
                                            } else {
                                                dispatch(
                                                    setAlertMsg(
                                                        "Please enter user email address for change password"
                                                    )
                                                );
                                                dispatch(setALertStatus(true));
                                            }
                                        }}
                                    >
                                        <div
                                            style={{ fontSize: "13px" }}
                                            className="flex justify-center whitespace-nowrap text-slate-500"
                                        >
                                            Forgot
                                            <label
                                                style={{
                                                    paddingLeft: "8px",
                                                    fontWeight: "500",
                                                    color: "#6B4226",
                                                }}
                                            >
                                                Password ?
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AlertModal />
            <Modal open={forget} onClose={() => setForget(false)}>
                <div
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "80%",
                        maxWidth: "600px",
                        backgroundColor: "#6B4226",
                        overflow: "hidden",
                        borderRadius: "8px",
                    }}
                >
                    <div
                        style={{
                            backgroundColor: "#6B4226",
                            padding: "12px 16px",
                            color: "white",
                            fontSize: "14px",
                        }}
                    >
                        genius office
                    </div>
                    <div style={{ backgroundColor: "white", padding: "16px" }}>
                        <div
                            style={{
                                display: "flex",
                                width: "100%",
                                padding: "16px 0",
                                alignItems: "center",
                            }}
                        >
                            <img
                                src={require("../utility/images/ico.png")}
                                style={{
                                    marginRight: "12px",
                                    width: "40px",
                                    height: "40px",
                                }}
                                alt="icon"
                            />
                            <div>
                                <div
                                    style={{
                                        color: "#6B4226",
                                        fontSize: "14px",
                                        marginBottom: "8px",
                                    }}
                                >
                                    Click Continue to reset your Password.
                                    <div style={{ fontWeight: "600" }}>
                                        Reset Password link has been sent to
                                        your Email: {email}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                backgroundColor: "white",
                                paddingRight: "12px",
                            }}
                        >
                            <Button
                                size="small"
                                onClick={() => {
                                    forgetPassword(email);
                                    setForget(false);
                                }}
                                color="secondary"
                                variant="contained"
                                style={{ marginRight: "4px" }}
                            >
                                <div
                                    style={{
                                        textTransform: "capitalize",
                                        fontSize: "14px",
                                    }}
                                >
                                    Continue
                                </div>
                            </Button>

                            <Button
                                size="small"
                                onClick={() => setForget(false)}
                                color="secondary"
                                variant="contained"
                            >
                                <div
                                    style={{
                                        textTransform: "capitalize",
                                        fontSize: "14px",
                                    }}
                                >
                                    Cancel
                                </div>
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
            <CheckEmailModall />
            <EmailVerify email={location.state} />

            <EmailVerified />
        </div>
    );
};

export default LoginUi;
