import { Box, Modal } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import {
    selectAddressModalStatus,
    setALertStatus,
    setAddressModalStatus,
    setSessionExpired,
} from "../../Redux/modal";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { selectToken } from "../../Redux/security";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";

const AddressModal = ({
    handleClose,
    setGroupName,
    setAddress,
    setCountry,
    setState,
    setCity,
    setPinCode,
    setEmail,
    setPhone,
    setDateOfEst,
    setPresidentName,
    setPresidentbPlace,
    setPresidentDob,
    code,
    setCode,
}) => {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "70%",
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
    };

    const mobilestyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "95%",
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
    };

    const search = {
        width: "100%",
        margin: "auto",
        display: "flex",
    };

    const searchIcon = {
        position: "absolute",
        left: "1%",
        bottom: "2.5%",
    };

    const mobileSearchIcon = {
        position: "absolute",
        left: "81%",
        bottom: "9%",
    };

    const status = useSelector(selectAddressModalStatus);
    const [rows, setRows] = useState([]);
    const token = useSelector(selectToken);

    const columns = [
        {
            field: "code",
            headerName: "Code",
            maxWidth: 60,
            headerAlign: "center",
            align: "left",
        },
        {
            field: "name",
            headerName: "Group Name",
            width: 280,
            headerAlign: "center",
            align: "left",
        },
        {
            field: "mobile",
            headerName: "Phone",
            width: 150,
            headerAlign: "center",
            align: "left",
        },
        {
            field: "caddress",
            headerName: "Address",
            flex: 1,
            headerAlign: "center",
            align: "left",
        },
    ];

    const getData = async () => {
        try {
            const response = await axios
                .post(
                    "/api/wcp_masterReports/listAddressMaster",
                    {
                        code: "",
                        name: "",
                        city: "",
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                .then((p) => {
                    // console.log(p.data.response);
                    setRows(p.data.response);
                })

                .catch((error) => {
                    console.log(error.response.data.name);
                    if (error.response.data.name === "TokenExpiredError") {
                        dispatch(setSessionExpired(true));
                    }
                });
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getData();
    }, [status]);

    const [searchQuery, setSearchQuery] = useState("");
    const [filterData, setFilterData] = useState([]);

    const Filter = () => {
        const filtered = rows.filter((f) => {
            return f.name.toLowerCase().includes(searchQuery.toLowerCase());
        });
        setFilterData(filtered);
    };

    const Filter1 = () => {
        const filtered1 = rows.filter((e) => {
            return e.code.toString().includes(searchQuery);
        });
        setFilterData(filtered1);
    };

    useEffect(() => {
        const onHandleChange = () => {
            if (isNaN(searchQuery)) {
                Filter();
            } else {
                Filter1();
            }
        };
        onHandleChange();
    }, [searchQuery]);

    const handleOpen = () => {
        dispatch(setAddressModalStatus(false));
    };

    const dispatch = useDispatch();

    return (
        <>
            <div>
                <Modal
                    open={status}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={window.innerWidth <= 500 ? mobilestyle : style}>
                        <div className="w-full m-auto">
                            <div className="w-full max-xl:w-full my-0 max-sm:mt-0 max-sm:w-full m-auto">
                                <div
                                    style={{ backgroundColor: "#e0e0e0" }}
                                    className="flex w-full py-2.5 rounded-t"
                                >
                                    <h3 className="w-full text-sm font-bold text-start ml-4 max-sm:ml-3">
                                        Address Master Search
                                    </h3>
                                    <CloseIcon
                                        sx={{ marginRight: "10px" }}
                                        onClick={handleOpen}
                                    />
                                </div>
                                <div>
                                    <DataGrid
                                        headerHeight={45}
                                        rowHeight={40}
                                        showCellRightBorder={true}
                                        showColumnRightBorder={true}
                                        getRowId={(p) => p.code}
                                        onRowClick={(p) => {
                                            // console.log(p.row);
                                            setGroupName(p.row.name);
                                            setAddress(p.row.caddress);
                                            setCountry(p.row.country);
                                            setState(p.row.state);
                                            setCity(p.row.city);
                                            setPinCode(p.row.pin_code);
                                            setEmail(p.row.email);
                                            setPhone(p.row.mobile);
                                            setDateOfEst(
                                                moment(p.row.doe).format(
                                                    "YYYY-MM-DD"
                                                )
                                            );

                                            setPresidentName(p.row.name);
                                            setPresidentDob(
                                                moment(p.row.dob).format(
                                                    "YYYY-MM-DD"
                                                )
                                            );
                                            setPresidentbPlace(
                                                p.row.birth_place
                                            );

                                            dispatch(
                                                setAddressModalStatus(false)
                                            );

                                            setCode(p.row.code);
                                        }}
                                        style={{
                                            width: "100%",
                                            height: "350px",
                                            margin: "auto",
                                            fontSize: "12px",
                                        }}
                                        rows={
                                            filterData.length > 0
                                                ? filterData
                                                : rows
                                        }
                                        columns={columns}
                                        sx={{
                                            ".MuiDataGrid-columnHeaderTitle": {
                                                fontWeight: "bold",
                                            },
                                        }}
                                        hideFooter={true}
                                    />
                                </div>
                            </div>

                            <div style={search}>
                                <SearchIcon
                                    style={{ color: "orange" }}
                                    sx={
                                        window.innerWidth <= 500
                                            ? mobileSearchIcon
                                            : searchIcon
                                    }
                                />

                                <input
                                    value={searchQuery}
                                    onChange={(e) =>
                                        setSearchQuery(e.target.value)
                                    }
                                    id="searchInput"
                                    type="text"
                                    placeholder="Search here"
                                    class="block w-full mx-1 mb-1 px-8 text-gray-900 shadow-sm ring-1 ring-inset ring-orange border-1 max-xl:border-2 max-sm:border-0  mt-2   focus:bg-white placeholder:text-gray-400 outline-orange  py-2 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                    </Box>
                </Modal>
            </div>
        </>
    );
};

export default AddressModal;
