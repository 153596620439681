import React, { useEffect, useState } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import moment from 'moment/moment';
import { useDispatch, useSelector } from 'react-redux';
import { selectFromDate, selectHomeFbPosts, selectToDate, setToDate, setfromDate, setsinglePostDetails } from '../../utility/Redux/facebook';
import GetPosts from '../../functions/getPosts';
import { Button } from '@mui/material';
import middele from '../../utility/images/middleDefault.jpg'
import left from '../../utility/images/left.jpg'
import right from '../../utility/images/right.jpg'


 const width = window.innerWidth/100
 const height = window.innerHeight/100
 const BlogSectionThree = () => {
    const from = moment().startOf('year').format('YYYY-MM-DD')
    const to = moment().format('YYYY-MM-DD')
    const [posts,setPosts] = useState([])
    const dispatch = useDispatch()
    const HomeFbPosts = useSelector(selectHomeFbPosts)
    const navigate = useNavigate()
 

    const getPosts = ()=>{
  
    
            let list = [];
      for (let i = 0; i < 3; i++) {
       
        if(HomeFbPosts[i]){
           
            list.push({
                            message: HomeFbPosts[i].message,
                            image: HomeFbPosts[i].image,                            
                            date: HomeFbPosts[i].date,
                            id: HomeFbPosts[i].id,
                            approved:HomeFbPosts[i].approved,
                            video :HomeFbPosts[i].video,
                            post_type : HomeFbPosts[i].post_type,
                            defaultImg: i === 0 ? left : i ===1 ? middele : right
                    })
        }
     
      }
            setPosts(list)
   
            
 
    }

 

useEffect(()=>{
   GetPosts(dispatch,from,to)  
},[])

useEffect(()=>{
    getPosts()
},[HomeFbPosts])

        let publicUrl = process.env.PUBLIC_URL+'/'
        const data = 'Hello, next page!';

        return (
            <>
                <section  className="blog-three">
                    <div  className="container">
                    
                        <div className="section-title text-center">
                         
                            <h2 className="section-title__title"> Recent Activities</h2>
                        </div>

                        <div className="row">
                         
                            {posts[0] ? posts.map((post,index)=>{
                              {
                           
                                return(
                               
                                      <div key={index} className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="100ms">
                                {/* Blog Three Single */}
                                
                                <div  style={{borderRadius:10,overflow:'hidden',backgroundColor:'#f2f3f5'}}  className="blog-three__single">
                                    <div  className="blog-three__img">
                                        <img  src={post.image} alt=""  onError={(e) => {
    e.target.src = post.defaultImg // Set the default image source here
  }}   />
                                    </div>
                                    <div style={{backgroundColor:'#f7f8fa'}} onClick={()=>{
                                        dispatch(setsinglePostDetails(post))
                                        localStorage.setItem('singlePostDetails', JSON.stringify(post))
                                    }} className="blog-three__content">
                                        <ul className="list-unstyled blog-three__meta">
                                        
                                            <li><Link style={{textDecoration:'none'}}  to={process.env.PUBLIC_URL + `/blog-details`}><i className="far fa-clock"></i> by {post.post_type === "FB" ? 'FB' :"Admin"} </Link></li>
                                            <li><Link style={{textDecoration:'none'}} to={process.env.PUBLIC_URL + `/blog-details`}><i className="far fa-calendar"></i> { moment(post.date).format('YYYY-MM-DD')}</Link></li>
                                        </ul>
                                        <h3 className="blog-three__title "><Link style={{textDecoration:'none'}} to={process.env.PUBLIC_URL + `/blog-details`}> <p style={{
                                           
                                           
                                        }} className='' > {post.message ?  post.message  : '#Indian Sikh Community Italy'}</p> </Link></h3>
                                    
                                    </div>
                                </div>
                            </div>
                            
                                )
                            }
                            }) :null}
                          
                           
                        </div>
                        <div style={{width:'100%',justifyContent:'end',display:'flex',flexDirection:'row'}}>
                            <Button onClick={()=>{
                                navigate('/Archive')

                            }} variant='outlined' style={{borderColor:'#ff6f0f',backgroundColor:'white' ,marginLeft:8,color:'black'}}>{moment().format('YYYY')}</Button>
                      
                            <Button onClick={()=>{
                                navigate('/Archive')
                                dispatch(setfromDate(moment(moment().subtract(1,'year').format('YYYY')).startOf('year').format('YYYY-MM-DD')))
                                dispatch(setToDate( moment(moment().subtract(1,'year').format('YYYY')).endOf('year').format('YYYY-MM-DD')))
                                
                            }}  variant='outlined' style={{borderColor:'#ff6f0f',backgroundColor:'white' ,marginLeft:8,marginRight:8,color:'black'}}>{moment().subtract(1,'y').format('YYYY')}</Button>
                            <Button onClick={()=>{
                                navigate('/Archive')

                            }}  variant='outlined' style={{borderColor:'#ff6f0f',backgroundColor:'white' ,color:'black',transform:'none',transition:'none',textTransform: 'none'}}> <div>Archive</div>  </Button>
                    </div>
                    </div>
                   
                </section>
            </>
        )
    }

export default BlogSectionThree;