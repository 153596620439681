import React from "react";
import { Link } from "react-router-dom";

export default class TeamOne extends React.Component {
    render() {
        let publicUrl = process.env.PUBLIC_URL + "/";
        return (
            <>
                <section className="team-one">
                    <div className="container">
                        <div className="section-title text-center">
                            <span className="section-title__tagline">
                                Our Group
                            </span>
                            <h2 className="section-title__title">
                                Meet Our Group Members{" "}
                            </h2>
                        </div>
                        <div className="row">
                            <div
                                className="col-xl-4 col-lg-4 wow fadeInUp"
                                data-wow-delay="200ms"
                            >
                                <div className="team-one__single">
                                    <div className="team-one__img">
                                        <img
                                            src={
                                                publicUrl +
                                                "assets/images/team/logo.jpg"
                                            }
                                            alt=""
                                        />
                                    </div>
                                    <div className="team-one__content">
                                        <h4 className="team-one__name ">
                                            Major Singh Mann
                                        </h4>
                                        <p className="team-one__title">
                                            Vice President
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col-xl-4 col-lg-4 wow fadeInLeft"
                                data-wow-delay="100ms"
                            >
                                {/* Team One Single */}
                                <div className="team-one__single">
                                    <div className="team-one__img">
                                        <img
                                            src={
                                                publicUrl +
                                                "assets/images/team/president.jpg"
                                            }
                                            alt=""
                                        />
                                    </div>
                                    <div className="team-one__content">
                                        <h4 className="team-one__name">
                                            Sukhdev Singh Kang
                                        </h4>
                                        <p className="team-one__title">
                                            President
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-xl-4 col-lg-4 wow fadeInRight"
                                data-wow-delay="100ms"
                            >
                                {/* Team One Single */}
                                <div className="team-one__single">
                                    <div className="team-one__img">
                                        <img
                                            src={
                                                publicUrl +
                                                "assets/images/team/logo.jpg"
                                            }
                                            alt=""
                                        />
                                    </div>
                                    <div className="team-one__content">
                                        <h4 className="team-one__name">
                                            Raghvir Singh
                                        </h4>
                                        <p className="team-one__title">
                                            Senior Memeber
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}
