import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../images/ico.png";
import {
    SelectAlertStatus,
    selectAlertMsg,
    setALertStatus,
    selectAlertOkStatus,
    setAlertOkStatus,
    setloading,
} from "../../Redux/modal";

export default function AlertModal() {
    const dispatch = useDispatch();
    const alertStatus = useSelector(SelectAlertStatus);
    const alertMsg = useSelector(selectAlertMsg);
    const okStatus = useSelector(selectAlertOkStatus);

    const handleClose = () => dispatch(setALertStatus(false));

    const myFunction = () => {
        // your logic here
        if (alertStatus) {
            if (Number(okStatus) === 0) {
                dispatch(setAlertOkStatus(Number(okStatus) + 1));
            } else {
                dispatch(setAlertOkStatus(0));
                dispatch(setALertStatus(false));
                dispatch(setloading(false));
            }
        }
    };

    useEffect(() => {
        const keyDownHandler = (event) => {
            if (event.key === "Enter") {
                event.preventDefault();
                // 👇️ your logic here
                if (alertStatus) {
                    myFunction();
                }
            }
        };

        document.addEventListener("keydown", keyDownHandler);

        return () => {
            document.removeEventListener("keydown", keyDownHandler);
        };
    }, [alertStatus, okStatus]);

    return (
        <div style={{ borderRadius: "8px" }}>
            <Modal open={alertStatus} onClose={handleClose}>
                <div
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        boxShadow: "24",
                        padding: "1rem",
                        borderRadius: "8px",
                        overflow: "hidden",
                        width: "auto",
                    }}
                >
                    <div
                        style={{
                            color: "white",
                            display: "flex",
                            justifyContent: "flex-start",
                            paddingLeft: "1rem",
                            fontWeight: "normal",
                            padding: "0.5rem 1rem",
                            fontSize: "1.1rem",
                            borderTopLeftRadius: 7,
                            borderTopRightRadius: 7,
                            backgroundColor: "#262424",
                            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
                        }}
                    >
                        Genius Office
                    </div>
                    <div
                        style={{
                            minWidth: "400px",
                            minHeight: "120px",
                            padding: "1rem",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            backgroundColor: "white",
                            borderBottomRightRadius: 7,
                            borderBottomLeftRadius: 7,
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                width: "100%",
                                padding: "1rem 0.75rem",
                                alignItems: "center",
                            }}
                        >
                            <img
                                src={logo}
                                style={{
                                    marginRight: "1rem",
                                    width: "40px",
                                    height: "40px",
                                }}
                                alt="Logo"
                            />
                            <div>
                                <div
                                    style={{
                                        fontSize: "16px",
                                    }}
                                >
                                    {alertMsg}
                                </div>
                            </div>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                paddingBottom: "0.75rem",
                                paddingRight: "1rem",
                                width: "100%",
                            }}
                        >
                            <Button
                                size={"small"}
                                onClick={handleClose}
                                variant="contained"
                                style={{
                                    fontWeight: "bold",
                                    fontSize: "13px",
                                    padding: "0.5rem 1rem",
                                    backgroundColor: "#262424",
                                    width: 100,
                                }}
                            >
                                Ok
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
